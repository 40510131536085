<template>
    <div class="article-box" v-loading="loading">
        <!-- 导航 -->
        <div class="page-nav clearfix">
            <ul>
                <li class = "nav-item">
                    会员管理&nbsp;&nbsp;/
                </li>
                <li>
                    <router-link class="nav-item" to="/institution/member/unit">
                        &nbsp;&nbsp;单位会员管理&nbsp;&nbsp;/
                    </router-link>
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;查看详情
                </li>
            </ul>
        </div>
        <!-- 标题 -->
        <div class="pag-title clearfix">
            <div class="page-title-name pull-left">单位会员详情</div>
        </div>

        <el-tabs v-model="activeName" type="card" @tab-click="tabChange" style="margin-top: 0">
            <el-tab-pane label="基本信息" name="first">
            </el-tab-pane>
            <el-tab-pane label="单位信息" name="second"></el-tab-pane>
            <el-tab-pane label="单位代表信息" name="third"></el-tab-pane>
        </el-tabs>

        
        <div v-if="activeName == 'first'">
            <el-descriptions    title="" :column="2"  border>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">单位全称</template>{{itemDetailBase.nickName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">统一社会信用代码</template> {{itemDetailBase.loginName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">大会等级</template>{{(plenaryLevel2[itemDetailBase.plenaryLevel]) || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">关注的分支机构</template> {{itemDetailBase.memberInstitutionsArr.join(',') || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">联系人姓名</template> {{itemDetailBase.nickName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">联系人电话</template>{{itemDetailBase.mobile || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">联系人邮箱</template> {{itemDetailBase.email || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">快递地址</template> {{itemDetailBase.contactDeliveryAddress || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">财务联系人姓名</template>{{itemDetailBase.financeContactName  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">财务联系人电话</template>{{itemDetailBase.financeContactPhone  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">邮编</template>{{itemDetailBase.financeContactZidCode || '-'}}
                </el-descriptions-item>
                
            </el-descriptions>
        </div>
        <div v-if="activeName == 'second'">
            <el-descriptions title="" :column="2"  border v-if="itemDetail.unitNature != 5">
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">单位性质</template>{{natureArr[itemDetail.unitNature] || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">单位全称</template>{{itemDetail.nickName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">统一社会信用代码</template>{{itemDetail.loginName || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">行业性质</template>{{industryNatureArr[itemDetail.unitEnterpriseVo.industryNature] || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">出资筹建单位</template>{{itemDetail.unitEnterpriseVo.funderUnit || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">业务经营范围</template>{{itemDetail.unitEnterpriseVo.businessScope || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">注册资金</template>{{itemDetail.unitEnterpriseVo.registeredFunds || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">注册时间</template>{{itemDetail.unitEnterpriseVo.registeredTime || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">总资产</template>{{itemDetail.unitEnterpriseVo.totalFunds|| '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职工人数</template>{{itemDetail.unitEnterpriseVo.stuffNumber || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">年营业收入</template>{{itemDetail.unitEnterpriseVo.annualRevenue || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">资产负债率</template>{{itemDetail.unitEnterpriseVo.assetRetio || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 具有大专及以上学历的中高层管理人员比例</template>{{itemDetail.unitEnterpriseVo.personnelProportion || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 通讯地址</template>{{itemDetail.unitEnterpriseVo.communicateAddress || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 法人代表</template>{{itemDetail.unitEnterpriseVo.legalRepresentative || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 国籍</template>{{itemDetail.unitEnterpriseVo.nationality || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 职务</template>{{itemDetail.unitEnterpriseVo.position || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 党组书记</template>{{itemDetail.unitEnterpriseVo.partySecretary || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 董事长</template>{{itemDetail.unitEnterpriseVo.chairman || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 总经理</template>{{itemDetail.unitEnterpriseVo.generalManager || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 副总经理</template>{{itemDetail.unitEnterpriseVo.deputyGeneralManager || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 网址</template>{{itemDetail.unitEnterpriseVo.url || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 微信公众号</template>{{itemDetail.unitEnterpriseVo.wechatOffcialNo || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 微博</template>{{itemDetail.unitEnterpriseVo.weibo || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 抖音</template>{{itemDetail.unitEnterpriseVo.tikTok || '-'}}
                </el-descriptions-item>
                 <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 单位地址</template>{{itemDetail.unitEnterpriseVo.contactDeliveryAddress|| '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 单位简介</template>{{itemDetail.unitEnterpriseVo.unitIntroduction || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 单位意见</template>{{itemDetail.unitEnterpriseVo.unitOpinion || '-'}}
                </el-descriptions-item>
                <!-- ----------- -->
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 填表人</template>{{itemDetail.preparer || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 联系电话</template>{{itemDetail.contactPhone || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 单位信息加盖公章表</template>
                    <div class="file-show-list">
                        <div v-for="(f) in itemDetail.unitPdfs " :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>

                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 企业营业执照或法人登记证书</template>
                    <el-image
                        v-for="img in itemDetail.businessLicenses"
                        :key="img"
                        style="width: 100px; height: 100px;margin-right:10px"
                        :preview-src-list="itemDetail.businessLicenses"
                        :src="img">
                    </el-image>
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label"> 其他加盖公章附件</template>
                    <div class="file-show-list">
                        <div v-for="(f) in itemDetail.others  " :key="f.id" class="file-show-item">
                             <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                             <span class="li-text">{{ f.remark }}</span>
                             <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                        </div>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
            <template  v-else>
                <el-descriptions title="单位信息" :column="2"  border>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">单位性质</template>{{natureArr[itemDetail.unitNature] || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">社会团体名称</template>{{itemDetail.nickName || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">民政部（局）登记注册账号</template>{{itemDetail.loginName || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">主管单位</template>{{itemDetail.unitCommunityVo.unitCompetent  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">成立时间</template>{{itemDetail.unitCommunityVo.unitEstablishTime   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">办公地址</template>{{itemDetail.unitCommunityVo.unitOfficeAddress   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">邮政编码</template>{{itemDetail.unitCommunityVo.unitZipCode   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">电话</template>{{itemDetail.unitCommunityVo.unitPhone   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">传真</template>{{itemDetail.unitCommunityVo.unitFax   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">单位会员</template>{{itemDetail.unitCommunityVo.memberUnitNumber   || '-'}}个
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">个人会员</template>{{itemDetail.unitCommunityVo.memberPersonNumber   || '-'}}个
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">分支机构</template>{{itemDetail.unitCommunityVo.institutionNumber   || '-'}}个
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">理事</template>{{itemDetail.unitCommunityVo.councilNumber   || '-'}}个
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">常务理事</template>{{itemDetail.unitCommunityVo.routineCouncilNumber  || '-'}}个
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">专职工作人员</template>{{itemDetail.unitCommunityVo.fullTimeNumber   || '-'}}个
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">联系人</template>{{itemDetail.unitCommunityVo.contacts   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">联系电话</template>{{itemDetail.unitCommunityVo.contactsPhone   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">传真</template>{{itemDetail.unitCommunityVo.contactsFax   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">办公地址</template>{{itemDetail.unitCommunityVo.contactsAddress   || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">邮政编码</template>{{itemDetail.unitCommunityVo.contactsZipCode   || '-'}}
                    </el-descriptions-item>

                </el-descriptions>
                <el-descriptions title="会长" :column="3"  border  v-if="itemDetail.unitCommunityVo.president">
                    <template v-for="(item,index) in itemDetail.unitCommunityVo.president" >
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'president1'+index">
                            <template slot="label">姓名</template>{{item.name  || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'president2'+index">
                            <template slot="label">现职务</template>{{item.nowPosition   || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'president3'+index">
                            <template slot="label">原职务</template>{{item.pastPosition   || '-'  }}
                        </el-descriptions-item>
                    </template>
                    <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!itemDetail.unitCommunityVo.president.length">
                        暂无数据
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="副会长" :column="3"  border  v-if="itemDetail.unitCommunityVo.vicePresident">
                    <template v-for="(item,index) in itemDetail.unitCommunityVo.vicePresident" >
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'vicePresident1'+index">
                            <template slot="label">姓名</template>{{item.name  || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'vicePresident2'+index">
                            <template slot="label">现职务</template>{{item.nowPosition   || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'vicePresident3'+index">
                            <template slot="label">原职务</template>{{item.pastPosition   || '-'  }}
                        </el-descriptions-item>
                    </template>
                    <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!itemDetail.unitCommunityVo.vicePresident.length">
                        暂无数据
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="秘书长" :column="3"  border  v-if="itemDetail.unitCommunityVo.secretary">
                    <template v-for="(item,index) in itemDetail.unitCommunityVo.secretary" >
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'secretary1'+index">
                            <template slot="label">姓名</template>{{item.name  || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'secretary2'+index">
                            <template slot="label">现职务</template>{{item.nowPosition   || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'secretary3'+index">
                            <template slot="label">原职务</template>{{item.pastPosition   || '-'  }}
                        </el-descriptions-item>
                    </template>
                    <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!itemDetail.unitCommunityVo.secretary.length">
                        暂无数据
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="副秘书长" :column="3"  border  v-if="itemDetail.unitCommunityVo. viceSecretary">
                    <template v-for="(item,index) in itemDetail.unitCommunityVo. viceSecretary" >
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'viceSecretary1'+index">
                            <template slot="label">姓名</template>{{item.name  || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'viceSecretary2'+index">
                            <template slot="label">现职务</template>{{item.nowPosition   || '-'  }}
                        </el-descriptions-item>
                        <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'viceSecretary3'+index">
                            <template slot="label">原职务</template>{{item.pastPosition   || '-'  }}
                        </el-descriptions-item>
                    </template>
                    <el-descriptions-item  label-class-name="no-my-label" content-class-name="my-content" v-if="!itemDetail.unitCommunityVo.viceSecretary.length">
                        暂无数据
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions title="" :column="2"  border >
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label"> 社团地址</template>{{itemDetail.contactDeliveryAddress || '-'}}
                    </el-descriptions-item>
                </el-descriptions>
                
                <el-descriptions title="其他信息" :column="2"  border >
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label"> 填表人</template>{{itemDetail.preparer || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label"> 联系电话</template>{{itemDetail.contactPhone || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label"> 单位信息加盖公章表</template>
                        <div class="file-show-list">
                            <div v-for="(f) in itemDetail.unitPdfs " :key="f.id" class="file-show-item">
                                <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                                <span class="li-text">{{ f.remark }}</span>
                                <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                            </div>
                        </div>

                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label"> 企业营业执照或法人登记证书</template>
                        <el-image
                            v-for="img in itemDetail.businessLicenses"
                            :key="img"
                            style="width: 100px; height: 100px;margin-right:10px"
                            :preview-src-list="itemDetail.businessLicenses"
                            :src="img">
                        </el-image>
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label"> 其他加盖公章附件</template>
                        <div class="file-show-list">
                            <div v-for="(f) in itemDetail.others  " :key="f.id" class="file-show-item">
                                <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                                <span class="li-text">{{ f.remark }}</span>
                                <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                            </div>
                        </div>
                    </el-descriptions-item>
                </el-descriptions>
            </template>
        </div>
        <div v-if="activeName == 'third'">
            <el-descriptions class="margin-top" title="单位代表信息" :column="2"  border>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">单位代表姓名</template>{{itemDetailInfo.name  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">性别</template>{{sexArr[itemDetailInfo.sex]  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">出生年月</template>{{(itemDetailInfo.birthday)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">民族</template>{{(itemDetailInfo.national)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">籍贯</template>{{(itemDetailInfo.nativePlace)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职务</template>{{(itemDetailInfo.position)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">职称</template>{{(itemDetailInfo.title)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">学历</template>{{(itemDetailInfo.education)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">学位</template>{{(itemDetailInfo.degree)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">手机</template>{{(itemDetailInfo.phone)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">办公电话</template>{{(itemDetailInfo.telephone)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">微信</template>{{(itemDetailInfo.wechat)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">邮箱</template>{{(itemDetailInfo.email)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">传真</template>{{(itemDetailInfo.fax)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">通讯地址</template>{{(itemDetailInfo.mailingAddress)  || '-'}}
                </el-descriptions-item>
                <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                    <template slot="label">邮政编码</template>{{(itemDetailInfo.zipCode)  || '-'}}
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions class="margin-top" title="个人简历" :column="3"  border>
                 <template  v-for="(i,index) in itemDetailInfo.memberForResumes">
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'time' + index">
                        <template slot="label">起止年月</template>{{i.time  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'unit' + index">
                        <template slot="label">单位</template>{{i.unit  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" :key="'position' + index">
                        <template slot="label">职务</template>{{i.position  || '-'}}
                    </el-descriptions-item>
                 </template>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content" >
                        <template slot="label">联系人姓名</template>{{itemDetailInfo.contactName  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">职务</template>{{itemDetailInfo.contactPosition  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">传真</template>{{itemDetailInfo.contactFax  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">手机</template>{{itemDetailInfo.contactPhone  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">办公电话</template>{{itemDetailInfo.contactTelephone  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">微信</template>{{itemDetailInfo.contactWechat  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">Email</template>{{itemDetailInfo.contactEmail  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">通讯地址</template>{{itemDetailInfo.contactMailingAddress  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">邮政编码</template>{{itemDetailInfo.contactZipCode  || '-'}}
                    </el-descriptions-item>
                    <el-descriptions-item  label-class-name="my-label" content-class-name="my-content">
                        <template slot="label">单位代表和联系人登记加盖公章表</template>
                        <div class="file-show-list">
                            <div v-for="(f) in itemDetailInfo.unitReprsts" :key="f.id" class="file-show-item">
                                <i class="el-icon-document" style="vertical-align: middle;margin-right: 10px;font-size: 18px;"></i>
                                <span class="li-text">{{ f.remark }}</span>
                                <i class="el-icon-download cursor pull-right downliad-file" @click="download(f.path, f.remark)"></i>
                            </div>
                        </div>
                    </el-descriptions-item>

                
            </el-descriptions>
        </div>

    </div>
</template>
<script>
import { FileDownload } from '@/http/api/FileApi';
import {InstitutionUnitMemberBase, InstitutionUnitMemberDetail, InstitutionUnitMemberInfo} from "@/http/api/InstitutionApi";
export default {
  components: { },
  data () {
    return {
        uId: '',
        userId: '',
        sexArr: ['', '男', '女'],
        plenaryLevel2: ['','会员','理事','常务理事','副会长','会员代表'],
        natureArr: ['','国企','事业','合资','民营','社团','其他'],
        // 1. 2.隧道工程 3.基础设施 4.装备技术 5.运输综合 6.物流
        industryNatureArr: ['','桥梁工程','隧道工程','基础设施','装备技术','运输综合','物流',],
        activeName: 'first', // 详情tab
        loading: false,
        itemDetailBase: {
            memberInstitutions: [],
            memberInstitutionsArr: []
        },
        itemDetail: {
            unitPdfs: [],
            others: [],
            businessLicenses: [],
            unitEnterpriseVo: {},
            unitCommunityVo: {
                president: [],
                vicePresident: [],
                secretary: [],
                viceSecretary: []
            }
        },
        itemDetailInfo: {
            memberForResumes: []
        },
    }
  },
  created () {
    this.uId = this.$route.query.id
    this.userId = this.$route.query.pid
    this.getDetail()
  },
  methods: {
    getDetail () {
        this.loading = true
        InstitutionUnitMemberBase({id: this.userId}).then(resp => {
            this.loading = false
            if (resp.code == '000') {
                this.itemDetailBase =  {...resp.result}
                this.itemDetailBase.memberInstitutions = resp.result.memberInstitutions || []

                this.itemDetailBase.memberInstitutionsArr = resp.result.memberInstitutions.filter(f => {
                    if (f.type == 2) {
                        return f
                    }
                }).map(s => {
                        return s.nickName
            })
            }
        })
    },
    download (path,name) {
        FileDownload(path, name)
    },
    tabChange (tab, event) {
        if (tab.index == 0) {
            this.activeName = 'first';
            if (!this.itemDetailBase.id) {
                this.getDetail()
            }
        } else if (tab.index == 1) {
            this.activeName = 'second';
            if (!this.itemDetail.id) {
                this.loading = true;
                InstitutionUnitMemberDetail({id: this.uId}).then(resp => {
                    this.loading = false;
                    if (resp.code === '000') {
                        this.itemDetail = {...resp.result}
                        this.itemDetail.unitPdfs = {...resp.result.unitPdfs} || []
                        this.itemDetail.others = {...resp.result.unitPdfs} || []
                        this.itemDetail.unitEnterpriseVo = {...resp.result.unitEnterpriseVo} || {}
                        this.itemDetail.unitCommunityVo = {...resp.result.unitCommunityVo} || {}
                        this.itemDetail.unitCommunityVo.memberForCommPositionVos  =  {...resp.result.unitCommunityVo.memberForCommPositionVos} || {}
                        this.itemDetail.businessLicenses = resp.result.businessLicenses.map(item => {
                            return process.env.VUE_APP_IMG_URL + item.path
                        })
                        this.itemDetail.unitCommunityVo.president = resp.result.unitCommunityVo.memberForCommPositionVos.filter(item => {
                            if (item.type == 1) {
                                return item
                            }
                        })
                        this.itemDetail.unitCommunityVo.vicePresident = resp.result.unitCommunityVo.memberForCommPositionVos.filter(item => {
                            if (item.type == 2) {
                                return item
                            }
                        })
                        this.itemDetail.unitCommunityVo.secretary = resp.result.unitCommunityVo.memberForCommPositionVos.filter(item => {
                            if (item.type == 3) {
                                return item
                            }
                        })
                        this.itemDetail.unitCommunityVo.viceSecretary = resp.result.unitCommunityVo.memberForCommPositionVos.filter(item => {
                            if (item.type == 4) {
                                return item
                            }
                        })
                    } else {
                        this.$Message.error("获取数据失败");
                    }
                })  
            }  
        } else if (tab.index == 2) {
            this.activeName = 'third';
            if (!this.itemDetailInfo.id) {
                this.loading = true;
                InstitutionUnitMemberInfo({id: this.uId}).then(resp => {
                    this.loading = false;
                    if (resp.code === '000') {
                        this.itemDetailInfo = {...resp.result}
                        this.itemDetailInfo.memberForResumes =  {...resp.result.memberForResumes} || []
                        this.itemDetailInfo.unitReprsts =  {...resp.result.unitReprsts} || []
                    } else {
                        this.$Message.error("获取数据失败");
                    }
                })
            }
        }
    },
   
  }
}
</script>
<style lang="less" scoped>
/deep/.el-tabs__header {
    margin-bottom: 0;
    margin-top: 15px;
}
/deep/.my-label {
    width: 150px;
}
/deep/.el-descriptions__header {
    margin-top: 20px;
}
/deep/.no-my-label {
    width: 0;
    padding: 0!important;
    border: none;
}

</style>
